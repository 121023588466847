import { createApp } from "vue"
import { createPinia } from "pinia"
import type { Router } from "vue-router"
import { markRaw } from "vue"
import App from "@/App.vue"
import globalFunctionMixin from "@/mixins/globalFunctionMixin"
import createRouter from "@/router"

declare module "pinia" {
    export interface PiniaCustomProperties {
        router: Router
    }
}

const app = createApp(App)
const pinia = createPinia()
const router = createRouter()

pinia.use(({ store }) => {
    store.router = markRaw(router)
})

app.use(pinia)
app.use(router)
app.mixin(globalFunctionMixin)
app.mount("#app")
