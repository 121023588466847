import { Router } from "vue-router"
import { ILoginResponse, ICurrentUser } from "@/models/user"
import Swal from "sweetalert2"
import { randomNumber } from "@/mock_api_server/utils"

export default {
    methods: {
        popUpMustLogin() {
            Swal.fire({
                icon: "warning",
                position: "top",
                title: "Oops...",
                text: "You must login first!",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            })
        },
        popUpNotHavePrevileges() {
            const settings = require("@/assets/settings.png")
            const id = randomNumber(10000, 99999)

            Swal.fire({
                icon: "warning",
                position: "top",
                title: "Oops...",
                showConfirmButton: false,
                html: `
                    <p>You don't have enough privileges for this feature. Please upgrade your account.</p>
                    <br>
                    <div class="d-flex w-full justify-content-between">
                        <button type="button" id="cancel${id}" class="swal2-confirm swal2-styled" style="display: inline-block;" aria-label="">CANCEL</button>
                        <button type="button" id="control${id}" class="icon-button icon-button-xxlarge p-1" style="display: inline-block;" aria-label="">
                            <img src="${settings}" style="max-width:100%"  />
                        </button>
                    </div>
                `,
                didOpen: () => {
                    document.getElementById("cancel" + id)!.addEventListener("click", () => {
                        Swal.close()
                    })
                    document.getElementById("control" + id)!.addEventListener("click", () => {
                        Swal.close()
                        window.location.href = "/#/name-generator"
                    })
                },
            })
        },
        popUpPiggyBank() {
            const piggy = require("@/assets/saldo-in.png")
            const id = randomNumber(10000, 99999)

            Swal.fire({
                icon: "warning",
                position: "top",
                title: "Oops...",
                showConfirmButton: false,
                html: `
                    <p>Your piggy bank has not enough balance</p>
                    <br>
                    <div class="d-flex w-full justify-content-between">
                        <button type="button" id="cancel${id}" class="swal2-confirm swal2-styled" style="display: inline-block;" aria-label="">CANCEL</button>
                        <button type="button" id="piggy${id}" class="icon-button icon-button-xxlarge p-1" style="display: inline-block;" aria-label="">
                            <img src="${piggy}" style="max-width:100%"  />
                        </button>
                    </div>
                `,
                didOpen: () => {
                    document.getElementById("cancel" + id)!.addEventListener("click", () => {
                        Swal.close()
                    })
                    document.getElementById("piggy" + id)!.addEventListener("click", () => {
                        Swal.close()
                        window.location.href = "/#/profile"
                    })
                },
            })
        },
        backRouter(router: Router) {
            router.back()
        },
        checkUserLevel(currentUser: ICurrentUser | null, min: number): Boolean {
            const level = ["userlead", "userbronze", "usersilver", "usergold", "usergoldplus"]
            if (currentUser == null) {
                this.popUpMustLogin()
                return false
            }

            const levelUser = level.indexOf(currentUser.user_name) + 1

            if (levelUser < min) {
                this.popUpNotHavePrevileges()
                return false
            }

            return true
        },
        checkUserLevelWithoutPopUp(currentUser: ICurrentUser | null, min: number): Boolean {
            if (currentUser == null) return false
            const level = ["userlead", "userbronze", "usersilver", "usergold", "usergoldplus"]
            const levelUser = level.indexOf(currentUser.user_name) + 1
            return levelUser >= min
        },
    },
}
