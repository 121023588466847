import { customAlphabet } from "nanoid/non-secure"

// https://zelark.github.io/nano-id-cc/
const nanoid = customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", 10)
const randomWords = require("random-words")

function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

function randomText(words: number = 1): string {
    return capitalizeFirstLetter(randomWords({ exactly: words, join: " " }))
}

function randomNumber(min: number = 1, max: number = 80): number {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function randomSlug(): string {
    return nanoid()
}

function buildPaginatedResults(results: object[]) {
    return {
        count: results.length,
        next: null,
        previous: null,
        results: results,
    }
}

export { randomText, randomNumber, randomSlug, buildPaginatedResults }
